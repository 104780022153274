import * as React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout/Layout'
import HeaderText from '../components/Text/HeaderText'
import { TickIcon } from '../components/Icon'
import { COLOR, STYLE } from '../style'
import { SCREEN_SIZE } from '../breakpoints'

const ThankYouIconContainer = styled.div`
  background-color: ${COLOR.PRIMARY};
  width: 5rem;
  height: 5rem;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  color: ${COLOR.WHITE};
  margin: 0 auto;
`

const StyledThankYouPage = styled(Layout)`
  align-items: center;
  display: flex;
  & > div {
    padding: 4rem 2rem;
  }
  ${SCREEN_SIZE.TABLET} {
    & > div {
      padding: 5rem;
    }
  }
`

const DescriptionText = styled.div`
  font-weight: ${STYLE.FONT_WEIGHT_LIGHT};
`

const StyledHeaderText = styled(HeaderText)`
  text-align: center;
  margin-bottom: 3rem;
`

const ThankYouPage = () => (
  <StyledThankYouPage>
    <ThankYouIconContainer>
      <TickIcon />
    </ThankYouIconContainer>
    <StyledHeaderText>
      Thank you for completing the script request.
    </StyledHeaderText>
    <DescriptionText>
      Our team will be in touch if we have any questions. Normally, scripts are
      requests are processed within 72 hours. Once your script is issued, the
      nominated pharmacy will be notified
    </DescriptionText>
  </StyledThankYouPage>
)

export default ThankYouPage
